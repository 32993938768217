.NavigationItem{
    margin: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: block;
    /* border: 1px solid #eee;
    box-shadow: 1px 2px #ccc; */

}

.NavigationItem a{
    width: 100%;
    color: midnightblue;
    text-decoration: none;
    box-sizing: border-box;
    display: block;
    font-size: 1.25em;

}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{
   
    /* color: #40A4c8; */
    color: #3c83b9;
}

@media (min-width: 500px){
    .NavigationItem{
        margin: 0;
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
    }
    
    .NavigationItem a{
        height: 100%;
        color: white;
     
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active{
        /* background-color: rgb(221, 173, 52); */
        background-color: transparent;
        border-bottom: 4px solid #3c83b9;
        color: white;
    }
    
}