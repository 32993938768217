.Introduction{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100vh;
background-color: #ece7e1;
color: black;
}
.Introduction h1{
    color: #0f1923;
    text-align: center;
    font-family: 'McLaren', cursive;
    font-size:1.8em;
    
}
.Introduction p{
    width: 70%;
    text-align: center;
    font-size:1.2em;

}

@media(min-width: 600px){
    .Introduction{
        height: 90vh;
    }
    .Introduction p{
        width: 500px;
        font-size: 1.2em;
    }
}