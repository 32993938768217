.SideDrawer{
    top:0;
    left: 0;
    position: fixed;
    width: 100%;
    /* max-width: 100%; */
    height: 100%;
    z-index: 200;
    background-color: #ece7e1;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    text-align: center;
 /* color: gold */
}
 .leftArrow{
    float:  right;
    margin: -32px -16px;
}

@media (min-width: 500px){
    .SideDrawer{
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close{
    transform: translateX(-100%);
}

.Logo{
    height: 11%;
    margin: 32px auto;
    width: 40%;
}