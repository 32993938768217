.TitleText{
    /* width:80%; */
    color: white;
    /* height: 40%; */
    /* margin: 30vh auto; */
    font-family: 'Orbitron', sans-serif;
}

.TitleText div div {
    display: flex;
    margin: auto;
}
.TitleText .head{
    font-size: 6.5vw;
   

}
.TitleText .intro{
    margin: 'auto';
    margin-bottom: 5%;
}

@media (min-width: 700px){
    .TitleText{
        /* width: 600px; */
        margin: auto;
    }
 
.TitleText .head{
    font-size: 4.5vw;
    /* line-height: 2.4rem; */
}
.TitleText .intro{
    margin-bottom: 1%;
}
}