.Picture{
    margin: 20px auto;
    margin-bottom: 8px;
    width: 50%;
    text-align: center;
}
.Picture img{
    width: 100%;
    border-radius: 50%;
    border: 2px solid #0f1923;


}

@media(min-width: 600px){
    .Picture img{
        width: 300px;
        margin-left: auto;
        margin-top: 5%;
    }
}
