.MainPageContent{
    width: 70%;
    margin: auto;
    padding: 8px ;
    box-sizing: border-box;
   
}

.block{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    margin: auto;
}

.MainPageContent h1{
    color: #0f1923;
    font-family: 'McLaren', cursive;
    font-size:1.6em;
    text-align: center;
}

.MainPageContent p{
   margin-top: 10%;
    text-align: center;
    font-size:1.2em;
}


.MainPageContent div{
    width: 60%;
    /* margin-right: 10%; */
    /* padding: 0;*/
    margin: auto;
}
.header{
    font-family: 'McLaren', cursive;
    color: #0f1923;
    font-size:2.5em;
    margin: 3% 0;
    text-align: center;
}
.white{
    color: white;
    background-color:  #0f1932;
}
.white h1{
    color: #fd4b57;
}


@media(min-width: 800px){
    .MainPageContent{
        display: flex;
        justify-content: center;
        align-items: center;
    margin-top: .5%;
    margin-bottom: 0;
    width: 80%;

    }
    .MainPageContent h1{
        font-size: 1.7em;
    }
    .right{
    flex-direction: row-reverse;
    }
    
    .MainPageContent div{
        width: 30%;
        margin: 0;
        margin-right: 10%;

    }
    .right div{
        margin-right: 0%;
    margin-left: 10%;
    }

    .MainPageContent img{
       width: 100%;
    }
    .MainPageContent p{
        width: 35%;
        text-align: justify;
        margin-top: 0;
        font-size: 1.2em;
    }
    .header{
        font-size: 3.5em;
    }
    .block{
        height: 80vh;
    }
}