.Logo{
    background-color: white;
    height: 100%;
    /* padding: 8px; */
    box-sizing: border-box;
    border-radius: 55%;
}
.Logo img{
    height: 100%;
    border-radius: 50%;
}