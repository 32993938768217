.App {
  /* text-align: center; */
/* background-color: #121250; */
background-color: #fd4b57;
color: white;
font-family: 'Montserrat', sans-serif;

}

.Fallback {
  height: 100vh;
  overflow: hidden;
   display: flex;
    justify-content: center;
     align-items: center;
      background-color: #0f1932;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: black;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  background-color: #3496df;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3c83b9; 
}