.Toolbar{
    height: 56px;
    width: 100%;
    top:0;
    left:0;
    position: fixed;
    display: flex;
    /* background-color: #703B09; */
    /* background-color: darkgoldenrod; */
    background-color: transparent;
    opacity: 0.8;
    justify-content: space-between;
    align-items: center;
   padding: 0 20px;
   box-sizing: border-box;
   z-index: 90;

}

.Toolbar nav{
    height: 100%;
}

.Logo{
    height: 80%;

}

@media(max-width:499px){
    .DesktopOnly{
        display: none;
    }
}